import React, { useState, useEffect } from "react";
import "../assets/styles/spinner.css";
import imdbLogo from "../assets/img/imdb-logo.svg";
import watchlyLogo from "../assets/img/watchly-logo.png"; 
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AverageRating from "./averageRating"; 
import GenreSelector from "./selectGenres.jsx";

let websiteUrl;
if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_DEV;
} else {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
}

const Spinner = () => {
  const [includeMovies, setIncludeMovies] = useState(true);
  const [includeSeries, setIncludeSeries] = useState(true);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [selectedGenre, setSelectedGenre] = useState("All");
  const [movies, setMovies] = useState([]); 
  const [series, setSeries] = useState([]);  
  const [uniqueGenres, setUniqueGenres] = useState([]); // État pour les genres uniques
  const navigate = useNavigate(); 

  // Récupérer les films et séries depuis l'API
  useEffect(() => {
    const fetchMoviesAndSeries = async () => {
      try {
        const movieResponse = await axios.get(`https://www.watchly.ovh/api/movies`);
        setMovies(movieResponse.data);  

        const seriesResponse = await axios.get(`https://www.watchly.ovh/api/series`);
        setSeries(seriesResponse.data);  
      } catch (error) {
        console.error("Erreur lors de la récupération des films et séries:", error);
      }
    };

    const getGenres = async () => {
      try {
        const response = await axios.get(`https://www.watchly.ovh/api/genres`);
        const genres = response.data; // Assure-toi que c'est un tableau d'objets avec { id_genres, name }
        setUniqueGenres(genres); // Mettre à jour l'état avec les genres récupérés
      } catch (error) {
        console.error("Erreur lors de la récupération des genres:", error);
      }
    };

    fetchMoviesAndSeries();
    getGenres();
  }, []);

  const spin = () => {
    const filteredMovies = [...movies, ...series]
      .filter(
        (item) =>
          (includeMovies && item.tmdb_id && item.runtime) || 
          (includeSeries && item.tmdb_id && !item.runtime)   
      )
      .filter(
        (item) => selectedGenre === "All" || item.genre.includes(Number(selectedGenre))
      );

    if (filteredMovies.length === 0) {
      alert("Aucun film ou série disponible pour les critères sélectionnés.");
      return;
    }

    const randomIndex = Math.floor(Math.random() * filteredMovies.length);
    setSelectedMovie(filteredMovies[randomIndex]);
  };

  const handleWatchNow = () => {
    if (selectedMovie) {
      navigate(`/movie/${selectedMovie.tmdb_id}`);
    }
  };

  return (
    <div>
      <div className="header">
        <h1>Roulette : Générateur de films et séries | Watchly </h1>
        <p>
          Sélectionnez vos préférences et cliquez sur "Lancer la roulette" pour
          découvrir un film ou une série aléatoire.
        </p>
      </div>
      <div className="spinner-container">
        <div className="options-container">
          <div className="genre-select">
            <label className="genre-label">Genre</label>
            <select
              value={selectedGenre}
              onChange={(e) => setSelectedGenre(e.target.value)}
              className="genre-drop"
            >
              <option value="All">Tous les genres</option>
              {uniqueGenres.map((genre, index) => (
                <option key={index} value={genre.id_genres}> 
                  {genre.name}
                </option>
              ))}
            </select>
          </div>
          <div className="options">
            <label className="type-label">Type</label>
            <label>
              <input
                type="checkbox"
                checked={includeMovies}
                onChange={() => setIncludeMovies(!includeMovies)}
              />
              Films
            </label>
            <label>
              <input
                type="checkbox"
                checked={includeSeries}
                onChange={() => setIncludeSeries(!includeSeries)}
              />
              Séries
            </label>
          </div>
          <div className="spin-button-container">
            <button onClick={spin} className="spin-button">
              Lancer la roulette
            </button>
          </div>
        </div>

        {selectedMovie && (
          <>
            <div className="img">
              <img
                src={`https://image.tmdb.org/t/p/w500/${selectedMovie.poster_path}`}
                alt={selectedMovie.title}
                className="movie-poster"
              />
            </div>
            <div className="movie-details">
              <h2>{selectedMovie.title}</h2>
              <p>Année: {new Date(selectedMovie.release_date).getFullYear()}</p>
              <p>
                Note: <img src={imdbLogo} alt="IMDb" className="imdb-logo" />{" "}
                <span>{selectedMovie.vote_average.toFixed(1)}</span>/10
              </p>
              <p>
                Note :<img src={watchlyLogo} alt="Watchly Logo" className="watchly-logo" />{" "}
                <AverageRating movieId={selectedMovie.tmdb_id} />
              </p>
              <button onClick={handleWatchNow} className="watch-now-button">
                Regardez Maintenant
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Spinner;
