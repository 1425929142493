import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const AuthContext = createContext();

let websiteUrl;
if (process.env.REACT_APP_BACKEND_ENVIRONMENT === "PROD") {
    websiteUrl = process.env.REACT_APP_BACKEND_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_BACKEND_ENVIRONMENT === "DEV") {
    websiteUrl = process.env.REACT_APP_BACKEND_WEBSITE_URL_DEV;
} else {
    websiteUrl = process.env.REACT_APP_BACKEND_WEBSITE_URL_DEFAULT;
}

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        console.log(`https://www.watchly.ovh/api/auth/status`);
        const response = await axios.get(`https://www.watchly.ovh/api/auth/status`, {
          withCredentials: true,
        });
        setIsAuthenticated(response.data.isAuthenticated);
        setUser(response.data.user);
      } catch (error) {
        console.error("Failed to fetch auth status:", error);
        setIsAuthenticated(false);
        setUser(null);
      }
    };

    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user }}>
      {children}
    </AuthContext.Provider>
  );
};
