// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    color: #ffffff;
    font-family: ProximaNovaBold, sans-serif;
}

.intro-section h1 {
    color: #ffffff;
}

.highlight {
    color: #0096c7;
    font-weight: bold;
}

.service-list-section {
    margin-top: 20px;
}

.service {
    margin-bottom: 30px;
}

.service h3 {
    color: #0096c7;
    font-size: 1.5em;
    margin-bottom: 10px;
}

.service p {
    line-height: 1.6;
}

.cta-section {
    padding: 20px;
    margin-top: 20px;
    border-radius: 5px;
    text-align: center;
}

.cta-section h2 {
    margin-bottom: 10px;
    color: #ffffff;
}

.cta-section a {
    color: #0096c7;
    text-decoration: none;
    font-weight: bold;
}

.cta-section a:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Services.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,aAAa;IACb,cAAc;IACd,wCAAwC;AAC5C;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".services-container {\n    width: 80%;\n    margin: 0 auto;\n    padding: 20px;\n    color: #ffffff;\n    font-family: ProximaNovaBold, sans-serif;\n}\n\n.intro-section h1 {\n    color: #ffffff;\n}\n\n.highlight {\n    color: #0096c7;\n    font-weight: bold;\n}\n\n.service-list-section {\n    margin-top: 20px;\n}\n\n.service {\n    margin-bottom: 30px;\n}\n\n.service h3 {\n    color: #0096c7;\n    font-size: 1.5em;\n    margin-bottom: 10px;\n}\n\n.service p {\n    line-height: 1.6;\n}\n\n.cta-section {\n    padding: 20px;\n    margin-top: 20px;\n    border-radius: 5px;\n    text-align: center;\n}\n\n.cta-section h2 {\n    margin-bottom: 10px;\n    color: #ffffff;\n}\n\n.cta-section a {\n    color: #0096c7;\n    text-decoration: none;\n    font-weight: bold;\n}\n\n.cta-section a:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
