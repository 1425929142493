import React, { useState, useEffect } from "react";
import "../assets/styles/AddComment.css";
import AuthModal from "./authModal"; 
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fasStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';

const AddComment = ({ movieId, onCommentSubmit }) => {
  const [text, setText] = useState('');
  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

let websiteUrl;
if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_DEV;
} else {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
}
  // Fonction pour récupérer les commentaires du film
  const fetchComments = async () => {
    try {
      const response = await fetch(`https://www.watchly.ovh/api/movies/${movieId}/comments`, {
        credentials: 'include' 
      });
      if (response.ok) {
        const data = await response.json();
        setComments(data);
      } else {
        console.error('Erreur lors de la récupération des commentaires');
      }
    } catch (error) {
      console.error('Erreur lors de la requête pour récupérer les commentaires:', error);
    }
  };

  useEffect(() => {
    fetchComments();
  }, [movieId]);

  const checkAuthentication = async () => {
    try {
      const response = await fetch(`https://www.watchly.ovh/api/auth/status`, {
        credentials: 'include' 
      });
      const data = await response.json();
      return data.isAuthenticated;
    } catch (error) {
      console.error('Erreur lors de la vérification de l\'authentification:', error);
      return false;
    }
  };

  const handleOpenModal = async () => {
    const isAuthenticated = await checkAuthentication();
    if (isAuthenticated) {
      setIsModalOpen(true);
    } else {
      setIsAuthModalOpen(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(`https://www.watchly.ovh/api/movies/${movieId}/comments`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text, rating }),
    });

    if (response.ok) {
      setText('');
      setRating(0);
      setIsModalOpen(false);
      fetchComments();
      if (onCommentSubmit) {
        onCommentSubmit();
      }
    } else {
      alert('Erreur lors de l\'ajout du commentaire');
    }
  };

  return (
    <div className="commentsSection">
      <button className="openCommentModalBtn" onClick={handleOpenModal}>
        Écrire un commentaire
      </button>

      {isAuthModalOpen && (
        <AuthModal showModal={isAuthModalOpen} toggleModal={() => setIsAuthModalOpen(false)} />
      )}

      {isModalOpen && (
        <div className="modal">
          <div className="modalContent">
            <span className="closeModalBtn" onClick={() => setIsModalOpen(false)}>&times;</span>
            <h3>Ajouter un commentaire</h3>
            <form onSubmit={handleSubmit}>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Écrivez votre commentaire..."
                required
              />
              <Rating
                fractions={2}
                initialRating={rating / 2}
                onChange={(rate) => setRating(rate * 2)}
                emptySymbol={<FontAwesomeIcon icon={farStar} className="star-empty" />}
                fullSymbol={<FontAwesomeIcon icon={fasStar} className="star-full" />}
                halfSymbol={<FontAwesomeIcon icon={faStarHalfAlt} className="star-half" />}
              />
              <button type="submit">Soumettre</button>
            </form>
          </div>
        </div>
      )}

      <h3>Commentaires</h3>
      <div className="comment-overflow">
        {comments.length > 0 ? (
          comments.map((comment) => (
            <div key={comment._id} className="comment">
              <p><strong>{comment.user.name}</strong> a écrit le {new Date(comment.createdAt).toLocaleDateString()}</p>
              <p><strong>Note :</strong> {comment.rating}/10</p>
              <p>{comment.text}</p>
            </div>
          ))
        ) : (
          <p>Aucun commentaire pour ce film.</p>
        )}
      </div>
    </div>
  );
};

export default AddComment;
