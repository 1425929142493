import React, { useState, useEffect } from "react";
let websiteUrl;
if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_DEV;
} else {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
}
const AverageRating = ({ movieId, refreshTrigger }) => {
  const [averageRating, setAverageRating] = useState(0);

  // Fonction pour récupérer les commentaires
  const fetchComments = async () => {
    try {
      const response = await fetch(`https://www.watchly.ovh/api/movies/${movieId}/comments`, {
        credentials: 'include'
      });
      const data = await response.json();
      calculateAverageRating(data); 
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    fetchComments();
  }, [movieId, refreshTrigger]);

  // Fonction pour calculer la moyenne des notes
  const calculateAverageRating = (comments) => {
    if (comments.length === 0) {
      setAverageRating(0);
      return;
    }
    const totalRating = comments.reduce((sum, comment) => sum + comment.rating, 0);
    const average = totalRating / comments.length;
    setAverageRating(average);
  };

  return (
      <span>{averageRating.toFixed(1)} / 10</span>
  );
};

export default AverageRating;
