// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    color: #ffffff;
    font-family: ProximaNovaBold, sans-serif;
}

p {
    font-size: 1.2em;
    line-height: 1.5;
    color: #ffffff;
}

.intro-section h1 {
    color: #ffffff;
    font-family: proximaNovaBold, sans-serif;
    font-size: 3em;
}

.highlight {
    color: #0096c7;
    font-weight: bold;
}

.mission-section {
    padding: 20px;
    margin-top: 20px;
    border-radius: 5px;
}

.team-grid {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.team-member {
    text-align: center;
    flex-basis: 30%;
}

.team-member img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

.values-section ul {
    list-style-type: square;
    padding-left: 20px;
}

.join-us-section a {
    color: #0096c7;
    text-decoration: none;
}

.join-us-section a:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/AboutUs.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,aAAa;IACb,cAAc;IACd,wCAAwC;AAC5C;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,wCAAwC;IACxC,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".about-us-container {\n    width: 80%;\n    margin: 0 auto;\n    padding: 20px;\n    color: #ffffff;\n    font-family: ProximaNovaBold, sans-serif;\n}\n\np {\n    font-size: 1.2em;\n    line-height: 1.5;\n    color: #ffffff;\n}\n\n.intro-section h1 {\n    color: #ffffff;\n    font-family: proximaNovaBold, sans-serif;\n    font-size: 3em;\n}\n\n.highlight {\n    color: #0096c7;\n    font-weight: bold;\n}\n\n.mission-section {\n    padding: 20px;\n    margin-top: 20px;\n    border-radius: 5px;\n}\n\n.team-grid {\n    display: flex;\n    gap: 20px;\n    margin-top: 20px;\n}\n\n.team-member {\n    text-align: center;\n    flex-basis: 30%;\n}\n\n.team-member img {\n    border-radius: 50%;\n    width: 150px;\n    height: 150px;\n}\n\n.values-section ul {\n    list-style-type: square;\n    padding-left: 20px;\n}\n\n.join-us-section a {\n    color: #0096c7;\n    text-decoration: none;\n}\n\n.join-us-section a:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
