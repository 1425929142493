import React from 'react';
import "../assets/styles/AboutUs.css";
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import team1 from '../assets/img/balthazar.png';
import team2 from '../assets/img/swan.png';

const AboutUsPage = () => {
  return (
    <div>
        <Navbar />
    <div className="about-us-container">
      <section className="intro-section">
        <h1>À propos de Watchly</h1>
        <p>
          Bienvenue chez <span className="highlight">Watchly</span>, votre plateforme
          de choix pour découvrir les meilleurs films et séries. Notre objectif est de
          révolutionner votre expérience de visionnage en vous offrant des contenus
          personnalisés, adaptés à vos préférences et à vos envies.
        </p>
      </section>

      <section className="mission-section">
        <h2>Notre Mission</h2>
        <p>
          Chez Watchly, nous croyons que chaque spectateur mérite une expérience de visionnage
          qui lui ressemble. C'est pourquoi nous mettons en avant une sélection minutieuse de contenus,
          allant des classiques intemporels aux nouveautés incontournables. Nous voulons rendre la
          découverte de nouveaux films et séries aussi simple que possible.
        </p>
      </section>

      <section className="team-section">
        <h2>Notre Équipe</h2>
        <p>
          Notre équipe est composée de professionnels passionnés par le cinéma, les séries et la
          technologie. Ensemble, nous nous efforçons d'innover et de vous offrir la meilleure
          expérience possible sur Watchly.
        </p>
        <div className="team-grid">
          <div className="team-member">
            <img src={team2}alt="Membre 1" />
            <h3>Swan KONG A SIOU</h3>
            <p>Chef de Projet</p>
          </div>
          <div className="team-member">
            <img src={team1} alt="Membre 2" />
            <h3>VAILLANT Balthazar</h3>
            <p>Responsable Développement</p>
          </div>
        </div>
      </section>

      <section className="values-section">
        <h2>Nos Valeurs</h2>
        <ul>
          <li>Innovation constante pour une expérience utilisateur optimale</li>
          <li>Engagement envers la qualité du contenu proposé</li>
          <li>Respect des données personnelles et de la vie privée de nos utilisateurs</li>
        </ul>
      </section>

      <section className="join-us-section">
        <h2>Rejoignez-nous</h2>
        <p>
          Nous sommes toujours à la recherche de talents passionnés par l'univers du
          divertissement et de la technologie.
        </p>
      </section>
    </div>
    <Footer />
    </div>
  );
};

export default AboutUsPage;
