import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar";
import Banner from "../components/banner";
import MovieList from "../components/movieList";
import SerieList from "../components/serieList"; // Nouveau composant
import axios from "axios";
import Footer from "../components/footer";
let websiteUrl;
if (process.env.REACT_APP_BACKEND_ENVIRONMENT === "PROD") {
  websiteUrl = process.env.REACT_APP_BACKEND_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_BACKEND_ENVIRONMENT === "DEV") {
  websiteUrl = process.env.REACT_APP_BACKEND_WEBSITE_URL_DEV;
} else {
  websiteUrl = process.env.REACT_APP_BACKEND_WEBSITE_URL_PROD;
}
const HomePage = () => {
  const [userLists, setUserLists] = useState([]);
  const [movies, setMovies] = useState([]);
  const [series, setSeries] = useState([]);

  // Récupérer les listes d'utilisateur depuis le backend
  useEffect(() => {
    const fetchUserLists = async () => {
      try {
        const response = await axios.get(`https://www.watchly.ovh/api/lists`, {
          withCredentials: true,
        });
        setUserLists(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des listes d'utilisateur:", error);
      }
    };

    fetchUserLists();
  }, []);

  // Récupérer les films
  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await axios.get(`https://www.watchly.ovh/api/movies`);
        setMovies(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des films:", error);
      }
    };

    fetchMovies();
  }, []);

  // Récupérer les séries
  useEffect(() => {
    const fetchSeries = async () => {
      try {
        const response = await axios.get(`https://www.watchly.ovh/api/series`);
        setSeries(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des séries:", error);
      }
    };

    fetchSeries();
  }, []);

  // Ajouter un film à une liste
  const handleAddMovieToList = async (listId, movieId) => {
    try {
      const movieToAdd = movies.find((movie) => movie.tmdb_id === movieId);
      if (!movieToAdd) return;

      const response = await axios.post(
        `https://www.watchly.ovh/api/lists/${listId}/movies`,
        { movie: movieToAdd },
        { withCredentials: true }
      );

      if (!response.ok) {
        console.error("Erreur lors de l'ajout du film à la liste:", response.message);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du film à la liste:", error);
    }
  };
const handleAddSerieToList = async (listId, serieId) => {
  try {
    const serieToAdd = series.find((serie) => serie.tmdb_id === serieId);
    if (!serieToAdd) return;

    const response = await axios.post(
      `https://www.watchly.ovh/api/lists/${listId}/series`,
      { serie: serieToAdd },
      { withCredentials: true }
    );

    if (!response.ok) {
      console.error("Erreur lors de l'ajout de la serie à la liste:", response.message);
    }
  } catch (error) {
    console.error("Erreur lors de l'ajout de la serie à la liste:", error);
  }
};
  return (
    <div>
      <Navbar />
      <Banner />
      <MovieList 
        movies={movies} 
        userLists={userLists} 
        handleAddMovieToList={handleAddMovieToList} 
      />
      <SerieList 
        userLists={userLists} 
        handleAddSerieToList={handleAddSerieToList} 
      />
      <Footer />
    </div>
  );
};

export default HomePage;
