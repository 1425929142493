import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/navbar.css'; // Réutilisation des styles de la navbar
import Navbar from '../components/navbar'; // Import de la navbar
let websiteUrl;
if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
    websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
    websiteUrl = process.env.REACT_APP_WEBSITE_URL_DEV;
  } else {
    websiteUrl = process.env.REACT_APP_WEBSITE_URL_DEFAULT;
}
const imageBaseUrl = "https://image.tmdb.org/t/p/w500";

function SearchPage() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const navigate = useNavigate();

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.length > 0) {
      try {
        const [moviesResponse, seriesResponse] = await Promise.all([
          axios.get(`https://www.watchly.ovh/api/search/movies?query=${encodeURIComponent(query)}`),
          axios.get(`https://www.watchly.ovh/api/search/series?query=${encodeURIComponent(query)}`)
        ]);

        setSearchResults([
          ...moviesResponse.data.map((result) => ({ ...result, type: "movie" })),
          ...seriesResponse.data.map((result) => ({ ...result, type: "serie" }))
        ]);

        setShowResults(true);
      } catch (error) {
        console.error("Erreur lors de la recherche :", error);
      }
    } else {
      setSearchResults([]);
      setShowResults(false);
    }
  };

  const handleSearchSubmit = (event) => {
    if (event.key === "Enter") {
      navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
    }
  };

  return (
    <div className="navbar-container">
      <Navbar />
      
      <div className="navbar-content">
        <div className="navbar-left">
          <div className="navbar-top">
            <div className="search-container">
              <input
                type="text"
                className="navbar-search"
                placeholder="Rechercher un film ou une série..."
                aria-label="Rechercher"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={handleSearchSubmit}
              />
              <span className="material-symbols-outlined search-icon" aria-hidden="true">
                search
              </span>
              {showResults && (
                <div className="search-results-container">
                  <button className="close-results" onClick={() => setShowResults(false)}>
                    ✕
                  </button>
                  <ul className="search-results">
                    {searchResults.map((result) => {
                      const title = result._source.title || result._source.name;
                      const year = new Date(result._source.release_date || result._source.first_air_date).getFullYear();
                      const posterPath = `${imageBaseUrl}${result._source.poster_path}`;

                      return (
                        <li
                          key={result._id}
                          onClick={() => {
                            navigate(`/${result.type === "movie" ? "movie" : "serie"}/${result._id}`);
                            setShowResults(false); 
                          }}
                          className="search-result-item"
                        >
                          <img src={posterPath} alt={title} className="search-result-poster" />
                          <div className="search-result-info">
                            <span className="search-result-title">{title}</span>
                            <span className="search-result-year">({year})</span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchPage;
