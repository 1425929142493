import React from "react";
import Navbar from "../components/navbar";
import Spinner from "../components/spinner";
import Footer from "../components/footer";

const SpinWatchPage = () => {
  return (
    <div>
      <Navbar />
      <Spinner />
      <Footer />
    </div>
  );
};

export default SpinWatchPage;
