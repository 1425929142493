import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../assets/styles/navbar.css";
import logo from "../assets/img/logo.png";
import AuthModal from "./authModal";
import { AuthContext } from "../components/contexts/AuthContext";

let websiteUrl;
if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_DEV;
} else {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_DEFAULT;
}

function Navbar() {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false); // Ajout d'un état pour afficher/masquer les résultats
  const navigate = useNavigate();

  const toggleModal = () => setShowModal(!showModal);

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.length > 0) {
      try {
        const [moviesResponse, seriesResponse] = await Promise.all([
          axios.get(`https://www.watchly.ovh/api/search/movies?query=${encodeURIComponent(query)}`),
          axios.get(`https://www.watchly.ovh/api/search/series?query=${encodeURIComponent(query)}`)
        ]);

        setSearchResults([
          ...moviesResponse.data.map((result) => ({ ...result, type: "movie" })),
          ...seriesResponse.data.map((result) => ({ ...result, type: "serie" }))
        ]);

        setShowResults(true); // Affiche le menu déroulant
      } catch (error) {
        console.error("Error searching movies and series:", error);
      }
    } else {
      setSearchResults([]);
      setShowResults(false); // Masquer le menu si pas de résultats
    }
  };

  const handleSearchSubmit = (event) => {
    if (event.key === "Enter") {
      navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
    }
  };

  const imageBaseUrl = "https://image.tmdb.org/t/p/w500";

  return (
    <div className="navbar-container">
      <nav className="navbar" aria-label="Menu principal">
        <div className="navbar-content">
          <div className="navbar-left">
            <div className="navbar-top">
              <Link to="/">
                <img src={logo} alt="Logo de Watchly" className="navbar-logo" />
              </Link>
              <div className="search-container">
                <input
                  type="text"
                  className="navbar-search"
                  placeholder="Rechercher..."
                  aria-label="Rechercher"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  onKeyDown={handleSearchSubmit}
                />
                <span className="material-symbols-outlined search-icon" aria-hidden="true">
                  search
                </span>
                {showResults && (
                  <div className="search-results-container">
                    <button className="close-results" onClick={() => setShowResults(false)}>
                      ✕
                    </button>
                    <ul className="search-results">
                      {searchResults.map((result) => {
                        const title = result._source.title;
                        const year = new Date(result._source.release_date).getFullYear();
                        const posterPath = `${imageBaseUrl}${result._source.poster_path}`;

                        return (
                          <li
                            key={result._id}
                            onClick={() => {
                              navigate(`/${result.type === "movie" ? "movie" : "serie"}/${result._id}`);
                              setShowResults(false); 
                            }}
                            className="search-result-item"
                          >
                            <img src={posterPath} alt={title} className="search-result-poster" />
                            <div className="search-result-info">
                              <span className="search-result-title">{title}</span>
                              <span className="search-result-year">({year})</span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="navbar-right">
          {isAuthenticated ? (
  <>
    <div className="navbar-login-profile">
      <div className="navbar-profile" onClick={toggleModal}>
        {user.avatar && (
          <img
            src={user.avatar}
            alt="Profile"
            className="navbar-profile-image"
            style={{ width: '40px', height: '40px', borderRadius: '50%' }}
          />
        )}
        <span className="navbar-profile-name">{user.fullName}</span>
      </div>
      <div className={`dropdown-menu ${showModal ? "show" : ""}`}>
  <div className="logout-box">
    <button
      className="dropdown-item"
      onClick={() => {
        if (websiteUrl) {
          window.location.href = `https://www.watchly.ovh/api/auth/logout`;
        } else {
          console.error("websiteUrl is not defined");
        }
      }}
    >
      Se déconnecter
    </button>
  </div>
</div>
    </div>
  </>
) : (
  <>
    <button className="navbar-link user" onClick={toggleModal} aria-label="Profil utilisateur">
      <span className="material-symbols-outlined">face_5</span>
    </button>
    <button className="navbar-link" onClick={toggleModal}>
      Se connecter
    </button>
    <span className="navbar-divider">|</span>
    <button className="navbar-link" onClick={toggleModal}>
      S'inscrire
    </button>
    <AuthModal showModal={showModal} toggleModal={toggleModal} />
  </>
)}


<button 
  className="navbar-button create-list-button" 
  onClick={() => {
    if (isAuthenticated) {
      navigate("/userlist");
    } else {
      setShowModal(true); // Ouvre la modal de connexion si non authentifié
    }
  }}
>
  <span className="material-symbols-outlined create-list" aria-hidden="true">
    format_list_bulleted
  </span>{" "}
  Mes Listes
</button>

          </div>
        </div>
        <div className="navbar-down">
          <Link to="/movies" className="navbar-link">
            Films
          </Link>
          <Link to="/series" className="navbar-link">
            Séries
          </Link>
          <Link to="/news" className="navbar-link">
            Nouveautés
          </Link>
          <Link 
  to={isAuthenticated ? "/spinwatch" : "#"} 
  className="navbar-link"
  onClick={(e) => {
    if (!isAuthenticated) {
      e.preventDefault();
      setShowModal(true);  
    }
  }}
>
  SpinWatch
</Link>

        </div>
      </nav>
    </div>
  );
}

export default Navbar;
