import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import "../assets/styles/footer.css";

const Footer = () => {
  const toggleMenu = () => {
    document.body.classList.toggle('open');
  };

  return (
    <>
      <div className="background"></div>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="footer-col">
              <h4>Entreprise</h4>
              <ul>
                <li><a href="/about-us">à propos de nous</a></li>
                <li><a href="/services">Nos services</a></li>
                <li><a href="/privacy-policy">Politique de confidentialité</a></li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>à définir</h4>
              <ul>
                <li><a href="/movies">Fims</a></li>
                <li><a href="/series">Séries</a></li>
                <li><a href="/news">Nouveautés</a></li>
                <li><a href="/spinwatch">Spinwatch</a></li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Nous suivre</h4>
              <div className="social-links">
                <a href="https://www.facebook.com/?locale=fr_FR">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.instagram.com/">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://fr.linkedin.com/">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <button className="footer-burger hover-transform" onClick={toggleMenu}></button>
    </>
  );
};

export default Footer;
