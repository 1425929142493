import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import ProductMoviePage from "../pages/ProductMoviePage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import UserListPage from "../pages/UserListPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import SpinWatchPage from "../pages/SpinWatchPage";
import { AuthProvider } from "./contexts/AuthContext";
import SearchPage from "../pages/SearchPage"; // Assurez-vous que ce composant existe
import ProductSeriePage from "../pages/ProductSeriePage";
import NewReleasesPage from "../pages/NewReleasePage";
import MoviePage from "../pages/MoviePage"; 
import SeriePage from "../pages/SeriePage";
import AboutUsPage from "../pages/AboutUsPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import ServicesPage from "../pages/ServicesPage";
function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<HomePage />} exact />
            <Route path="/forgot" element={<ForgotPasswordPage />} />
            <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
            <Route path="/movie/:id" element={<ProductMoviePage />} />
            <Route path="/serie/:id" element={<ProductSeriePage />} />
            <Route path="/spinwatch" element={<SpinWatchPage />} />
            <Route path="/userlist" element={<UserListPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/news" element={<NewReleasesPage />} />
            <Route path="/series" element={<SeriePage />} />
            <Route path="/movies" element={<MoviePage />} />  
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
            <Route path="/services" element={<ServicesPage />} /> 
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
