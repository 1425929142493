import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "../assets/styles/movieDetail.css"; 
import imdbLogo from "../assets/img/imdb-logo.svg";
import watchlyLogo from "../assets/img/watchly-logo.png";
import youtubeLogo from "../assets/img/youtube-logo.png"; 
import addIcon from "../assets/img/add-button.png";
import AddComment from "./addComment";
import AverageRating from "./averageRating";

let websiteUrl;
if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_DEV;
} else {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
}

const SerieDetail = () => {
  const { id } = useParams(); 
  const [serie, setSerie] = useState(null); 
  const [userLists, setUserLists] = useState([]);  
  const [refreshTrigger, setRefreshTrigger] = useState(0); 
  const [showModal, setShowModal] = useState(false);  
  const [isLoading, setIsLoading] = useState(false);  
  const [addedMovies, setAddedMovies] = useState({});  
  const [platforms, setPlatforms] = useState([]);  // Pour stocker les plateformes
  const [showFullSynopsis, setShowFullSynopsis] = useState(false);

  useEffect(() => {
    const fetchSerieDetails = async () => {
      try {
        const response = await axios.get(`https://www.watchly.ovh/api/series/${id}`);
        const serieData = response.data;

        // Extraire les plateformes et leurs logos
        const platformsList = [];
        if (Array.isArray(serieData.providers_id)) {
          serieData.providers_id.forEach(country => {
            Object.entries(country).forEach(([countryCode, countryData]) => {
              if (countryData && countryData.types) {
                ['flatrate', 'rent', 'buy'].forEach(type => {
                  if (Array.isArray(countryData.types[type])) {
                    countryData.types[type].forEach(providerArray => {
                      if (Array.isArray(providerArray) && providerArray.length > 0) {
                        platformsList.push({
                          name: providerArray[0], 
                          logo: providerArray[1], 
                        });
                      }
                    });
                  }
                });
              }
            });
          });
        }

        setSerie(serieData);
        setPlatforms(platformsList);  // Stocker les plateformes
      } catch (error) {
        console.error("Erreur lors de la récupération de la série :", error);
      }
    };

    fetchSerieDetails();
  }, [id]);

  // Récupérer les listes d'utilisateurs
  useEffect(() => {
    const fetchUserLists = async () => {
      try {
        const response = await axios.get(`https://www.watchly.ovh/api/lists`, { withCredentials: true });
        setUserLists(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des listes :", error);
      }
    };
    fetchUserLists();
  }, []);

  const handleSelectList = async (listId) => {
    try {
      setIsLoading(true);
      await axios.post(
        `https://www.watchly.ovh/api/lists/${listId}/series`, 
        { serie: serie }, 
        { withCredentials: true }
      );
      setAddedMovies({ ...addedMovies, [serie.tmdb_id]: true });
      closeModal();
    } catch (error) {
      console.error("Erreur lors de l'ajout de la série à la liste :", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddSerie = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const toggleSynopsis = (event) => {
    event.preventDefault();
    setShowFullSynopsis(!showFullSynopsis);
  };

  const handleCommentSubmit = () => {
    setRefreshTrigger((prev) => prev + 1); 
  };

  if (!serie) {
    return <div>Série non trouvée</div>;
  }

  return (
    <div className="movieDetailContainer">
      <h1>{serie.title}</h1>
      <div className="movieDetailContent">
        <img
          src={`https://image.tmdb.org/t/p/w500${serie.poster_path}`}
          alt={`Poster of ${serie.title}`}
        />
        <div className="movieDetailInfo">
          <p><strong>Année:</strong> {new Date(serie.release_date).getFullYear()}</p>
          <div className="headerContainer">
            <div className="logosContainer">
              <p className="rating">
                <img src={imdbLogo} alt="IMDB Logo" />
                {serie.vote_average ? serie.vote_average.toFixed(1) : "N/A"} / 10
              </p>
              <div className="watchlyRatingContainer">
                <img src={watchlyLogo} alt="Watchly Logo" className="watchlyLogo" />
                <AverageRating movieId={id} refreshTrigger={refreshTrigger} />
              </div>
            </div>
            <button onClick={handleAddSerie} className="addToListButton-product">
              <img src={addIcon} alt="Add" className="addIcon" />
              Ajouter à une liste personnalisée
            </button>
          </div>
          {platforms.length > 0 ? (
            <div className="platforms-section">
              <h3>Disponibilité sur les plateformes</h3>
              <div className="platforms-container">
                {Array.from(new Set(platforms.map(p => p.name)))
                  .map((platformName, index) => {
                    const platform = platforms.find(p => p.name === platformName);
                    const logoUrl = platform.logo.startsWith("/") 
                      ? `https://image.tmdb.org/t/p/original${platform.logo}`
                      : platform.logo;

                    return (
                      <div key={index} className="platform-item">
                        <img 
                          src={logoUrl} 
                          alt={`${platform.name} Logo`} 
                          title={platform.name}
                          className="platform-logo"
                        />
                        <span>{platform.name}</span> 
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <p>Aucune plateforme disponible</p>
          )}
        </div>
      </div>
      <p>
        <strong>Synopsis:</strong> {showFullSynopsis ? serie.overview : `${serie.overview.substring(0, 288)}...`}
        <button onClick={toggleSynopsis} className="toggleSynopsisBtn">
          {showFullSynopsis ? "Afficher moins" : "Afficher plus"}
        </button>
      </p>
      <div className="trailer">
        {serie.url_trailer ? (
          <a href={serie.url_trailer} target="_blank" rel="noopener noreferrer">
            <button className="watch-trailer-button">
              Voir le trailer <img src={youtubeLogo} alt="YouTube Logo" className="youtube-logo" />
            </button>
          </a>
        ) : (
          <p>Aucun trailer disponible</p>
        )}
      </div>
      <AddComment movieId={id} onCommentSubmit={handleCommentSubmit} />
      {showModal && (
        <div className="userlist-create-list-modal-container">
          <div className="userlist-create-list-modal">
            <div className="modal-content">
              <h2>Choisir une liste pour ajouter la série</h2>
              {isLoading ? (
                <div className="userlist-loading-spinner">
                  <div className="userlist-spinner"></div>
                </div>
              ) : userLists.length === 0 ? (
                <div>
                  <p className="userlist-error-message">Aucune liste créée.</p>
                  <Link to="/userlist" onClick={() => window.scrollTo(0, 0)}>
                    <button className="userlist-list-button">
                      Créer une liste
                    </button>
                  </Link>
                </div>
              ) : (
                <ul className="userlist-ul">
                  {userLists.map((list) => (
                    <li key={list._id}>
                      <button
                        className="userlist-list-button"
                        onClick={() => handleSelectList(list._id)}
                      >
                        {list.name}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
              <button className="userlist-close-button" onClick={closeModal}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SerieDetail;
