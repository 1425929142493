import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import CreateListButton from "../components/listButton";
import "../assets/styles/moviePage.css";
import axios from "axios";
import imdbLogo from "../assets/img/imdb-logo.svg";
import watchlyLogo from "../assets/img/watchly-logo.png";
import arrowIcon from "../assets/img/arrowRight.png";
import Footer from "../components/footer";
let websiteUrl;

if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_DEV;
} else {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
}
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};
const UserListPage = () => {
  const [lists, setLists] = useState([]);
  const [newListName, setNewListName] = useState(""); // État pour le nouveau nom de la liste
  const [editingList, setEditingList] = useState(null); // État pour la liste en cours de modification

  // Fonction pour récupérer les listes personnalisées de l'utilisateur
  useEffect(() => {
    const fetchLists = async () => {
      try {
        const response = await axios.get(`https://www.watchly.ovh/api/lists`, {
          withCredentials: true,
        });
        setLists(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des listes :", error);
      }
    };

    fetchLists();
  }, []);

  // Fonction pour sauvegarder une nouvelle liste
  const handleSaveList = async (listName) => {
    try {
      const response = await axios.post(
        `https://www.watchly.ovh/api/lists`,
        { name: listName },
        { withCredentials: true }
      );
      setLists([...lists, response.data]);
    } catch (error) {
      console.error("Erreur lors de la création de la liste :", error);
    }
  };

  // Fonction pour sauvegarder le nouveau nom de la liste
  const handleSaveListName = async () => {
    try {
      await axios.put(
        `https://www.watchly.ovh/api/lists/${editingList}`,
        { name: newListName },
        { withCredentials: true }
      );
      setLists(
        lists.map((list) =>
          list._id === editingList ? { ...list, name: newListName } : list
        )
      );
      setEditingList(null); // Ferme la modal
    } catch (error) {
      console.error("Erreur lors de la modification du nom de la liste :", error);
    }
  };

  // Fonction pour supprimer une liste
  const handleRemoveList = async (listId) => {
    try {
      await axios.delete(`https://www.watchly.ovh/api/lists/${listId}`, {
        withCredentials: true,
      });
      setLists(lists.filter((list) => list._id !== listId));
    } catch (error) {
      console.error("Erreur lors de la suppression de la liste :", error);
    }
  };

  // Fonction pour supprimer un film d'une liste
  const handleRemoveMovieFromList = async (listId, movieId) => {
    try {
      await axios.delete(
        `https://www.watchly.ovh/api/lists/${listId}/movies/${movieId}`,
        { withCredentials: true }
      );
      setLists(
        lists.map((list) =>
          list._id === listId
            ? { ...list, movies: list.movies.filter((movie) => movie.tmdb_id !== movieId) }
            : list
        )
      );
    } catch (error) {
      console.error("Erreur lors de la suppression du film de la liste :", error);
    }
  };

  // Fonction pour supprimer une série d'une liste
  const handleRemoveSerieFromList = async (listId, serieId) => {
    try {
      await axios.delete(
        `https://www.watchly.ovh/api/lists/${listId}/series/${serieId}`,
        { withCredentials: true }
      );
      setLists(
        lists.map((list) =>
          list._id === listId
            ? { ...list, series: list.series.filter((serie) => serie.tmdb_id !== serieId) }
            : list
        )
      );
    } catch (error) {
      console.error("Erreur lors de la suppression de la série de la liste :", error);
    }
  };
  const handleEditListName = (list) => {
    setEditingList(list._id); // Enregistrez uniquement l'identifiant de la liste
    setNewListName(list.name);
  };
  

  return (
    <div>
      <Navbar />
      <div className="moviePage-listContainer">
        <CreateListButton onSave={handleSaveList} />
        {lists.map((list) => (
          <div key={list._id} className="list-section">
            <div className="list-header">
              <Link onClick={() => handleEditListName(list)} className="edit-list-title">
                <h2>
                  {list.name} 
                  <img src={arrowIcon} alt="Right arrow icon" className="arrowIcon" />
                </h2>
              </Link>
              <button
                className="userlist-close-button-details"
                onClick={() => handleRemoveList(list._id)}
              >
                Supprimer la liste
              </button>
              <hr className="custom-separator" />
            </div>

            {(list.movies && list.movies.length > 0) || (list.series && list.series.length > 0) ? (
              <div className="moviePage-listContainer">
                <ul className="movieList">
                  {list.movies &&
                    list.movies.map((movie, index) => (
                      <li key={index} className="moviePage-item">
                        <button
                          className="deleteMovieButton"
                          onClick={() =>
                            handleRemoveMovieFromList(list._id, movie.tmdb_id)
                          }
                        >
                          ✕
                        </button>
                        <Link
                          to={`/movie/${movie.tmdb_id}`}
                          className="moviePage-link"
                        >
                          <div className="moviePage-imageContainer">
                            <img
                              src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                              alt={`Poster of ${movie.title}`}
                            />
                            <div className="moviePage-ratingImdb">
                              <img src={imdbLogo} alt="IMDB Logo" />
                              <span className="ratingValue">
                                {movie.vote_average
                                  ? movie.vote_average.toFixed(1)
                                  : 'N/A'}
                              </span>{' '}
                              / 10
                            </div>
                            <div className="moviePage-ratingWatchly">
                              <img src={watchlyLogo} alt="Watchly Logo" />
                              <span className="ratingValue">
  {movie.watchly_rating
    ? movie.watchly_rating.toFixed(1)
    : 0}
</span>{' '}
                              / 10
                            </div>
                          </div>
                          <div className="moviePage-titleContainer">
                            <p>{truncateText(movie.title, 12)}</p>
                            <p>({new Date(movie.release_date).getFullYear()})</p>
                          </div>
                        </Link>
                      </li>
                    ))}

                  {list.series &&
                    list.series.map((serie, index) => (
                      <li key={index} className="moviePage-item">
                        <button
                          className="deleteMovieButton"
                          onClick={() =>
                            handleRemoveSerieFromList(list._id, serie.tmdb_id)
                          }
                        >
                          ✕
                        </button>
                        <Link
                          to={`/serie/${serie.tmdb_id}`}
                          className="moviePage-link"
                        >
                          <div className="moviePage-imageContainer">
                            <img
                              src={`https://image.tmdb.org/t/p/w500${serie.poster_path}`}
                              alt={`Poster of ${serie.title}`}
                            />
                            <div className="moviePage-ratingImdb">
                              <img src={imdbLogo} alt="IMDB Logo" />
                              <span className="ratingValue">
                                {serie.vote_average
                                  ? serie.vote_average.toFixed(1)
                                  : 'N/A'}
                              </span>{' '}
                              / 10
                            </div>
                            <div className="moviePage-ratingWatchly">
                              <img src={watchlyLogo} alt="Watchly Logo" />
                              <span className="ratingValue">
                                {serie.watchly_rating
                                  ? serie.watchly_rating.toFixed(1)
                                  : '0'}
                              </span>{' '}
                              / 10
                            </div>
                          </div>
                          <div className="moviePage-titleContainer">
                            <p>{truncateText(serie.title, 12)}</p>
                            <p>({new Date(serie.release_date).getFullYear()})</p>
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            ) : (
              <p className="emptyListMessage">
                Aucun élément dans cette liste.{' '}
                <Link to="/search">Cliquer ici pour en ajouter.</Link>
              </p>
            )}
          </div>
        ))}

        {editingList && (
          <div className="userlist-create-list-modal-container">
            <div className="userlist-create-list-modal">
              <h2>Modifier le nom de la liste</h2>
              <input
                type="text"
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
                placeholder="Nouveau nom de la liste"
                className="userlist-list-name-input"
              />
              <div className="userlist-modal-buttons-details">
                <button onClick={() => setEditingList(null)}>Fermer</button>
                <button onClick={handleSaveListName}>Sauvegarder</button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default UserListPage;
