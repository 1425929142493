import React from "react";
import Navbar from "../components/navbar";
import ForgotPasswordPage from "../components/forgotPassword";
const ForgotPasswordPageComponent = () => {
  return (
    <div>
      <Navbar />
      <div className="main-content">
        <ForgotPasswordPage />
      </div>
    </div>
  );
};

export default ForgotPasswordPageComponent;
