import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/navbar";
import MovieDetail from "../components/movieDetail";
import Footer from "../components/footer";


const ProductPage = () => {
  const location = useLocation();
  const id = location.pathname.split("/movie/")[1];

  return (
    <div>
      <Navbar />
      <MovieDetail/>
      <Footer />
    </div>
  );
};

export default ProductPage;
