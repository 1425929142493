import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/navbar";
import SerieDetail from "../components/serieDetail"; 
import Footer from "../components/footer";

const ProductSerie = () => {
  const location = useLocation();
  const id = location.pathname.split("/serie/")[1];

  return (
    <div>
      <Navbar />
      <SerieDetail/>
      <Footer />
    </div>
  );
};

export default ProductSerie;
