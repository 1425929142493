import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import imdbLogo from "../assets/img/imdb-logo.svg";
import watchlyLogo from "../assets/img/watchly-logo.png";
import addIcon from "../assets/img/add-button.png";
import checkIcon from "../assets/img/check-icon.png";
import AverageRating from "../components/averageRating";
import "../assets/styles/moviePage.css";
import "../assets/styles/listButton.css"; 
import Footer from "../components/footer";
import rightArrowIcon from "../assets/img/arrowRight.png";
import Select from 'react-select';
let websiteUrl;

if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_DEV;
} else {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
}
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};

const MoviePage = () => {
  const [movies, setMovies] = useState([]);
  const [filteredMovies, setFilteredMovies] = useState([]); // Pour stocker les films filtrés
  const [addedMovies, setAddedMovies] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [userLists, setUserLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleMoviesCount, setVisibleMoviesCount] = useState(30);
  const [uniqueGenres, setUniqueGenres] = useState([]); 
  const [selectedGenre, setSelectedGenre] = useState("All");
  const [selectedProvider, setSelectedProvider] = useState('');
  const [providers, setProviders] = useState([]);
  const [selectedProviderLogo, setSelectedProviderLogo] = useState('');

  useEffect(() => {
    const fetchMoviesAndGenres = async () => {
      try {
        // Récupérer les films
        const moviesResponse = await axios.get(`https://www.watchly.ovh/api/movies`);
        const sortedMovies = moviesResponse.data.sort((a, b) => b.popularity - a.popularity);
        setMovies(sortedMovies);
        setFilteredMovies(sortedMovies); // Initialiser les films filtrés avec tous les films
  
        // Extraire les providers uniques
        const allProviders = new Set();
  
        sortedMovies.forEach(movie => {
          if (Array.isArray(movie.providers_id)) {
            // Parcourir chaque objet dans le tableau providers_id
            movie.providers_id.forEach(country => {
              Object.entries(country).forEach(([countryCode, countryData]) => {
                if (countryData && countryData.types) {
  
                  // Parcourir chaque type de diffusion (flatrate, rent, buy)
                  ['flatrate', 'rent', 'buy'].forEach(type => {
                    if (Array.isArray(countryData.types[type])) {
                      countryData.types[type].forEach(providerArray => {
                        if (Array.isArray(providerArray) && providerArray.length > 0) {
                          allProviders.add(providerArray[0]); // Ajoute le nom de la plateforme
                        }
                      });
                    } else {
                    }
                  });
                } else {
                }
              });
            });
          } else {
          }
        });
  
        const providersArray = Array.from(allProviders);
  
        if (providersArray.length === 0) {
          console.warn("Aucun fournisseur trouvé.");
        }

        setProviders(providersArray); // Met à jour l'état des fournisseurs
  
        // Récupérer les genres
        const genresResponse = await axios.get(`https://www.watchly.ovh/api/genres`);
        const genres = genresResponse.data;
        setUniqueGenres(genres);
  
      } catch (error) {
        console.error("Erreur lors de la récupération des films ou des genres :", error);
      }
    };
  
    fetchMoviesAndGenres(); // Appel asynchrone regroupé
  }, []);
  
  

  useEffect(() => {
    const fetchUserLists = async () => {
      try {
        const response = await axios.get(`https://www.watchly.ovh/api/lists`, { withCredentials: true });
        setUserLists(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des listes de l'utilisateur :", error);
      }
    };

    fetchUserLists();
  }, []);

  useEffect(() => {
    const addedMoviesMap = {};

    userLists.forEach((list) => {
      list.movies.forEach((movie) => {
        addedMoviesMap[movie.tmdb_id] = true;
      });
    });

    setAddedMovies(addedMoviesMap);
  }, [userLists]);

  useEffect(() => {
    let filtered = movies;
  
    // Filtrer par genre sélectionné (si nécessaire)
    if (selectedGenre !== "All") {
      filtered = filtered.filter(movie => movie.genre && movie.genre.includes(Number(selectedGenre)));
    }
  
    // Variable pour stocker le logo du fournisseur sélectionné
    let selectedProviderLogo = '';
  
    // Filtrer par fournisseur sélectionné
    if (selectedProvider) {
      filtered = filtered.filter(movie => {
        if (!movie.providers_id) {
          console.log(`No providers_id for movie: ${movie.title}`);
          return false; // Si aucun providers_id n'est présent, exclure le film
        }
  
        // Vérifier si providers_id est un tableau ou un objet
        const providers = Array.isArray(movie.providers_id) ? movie.providers_id : [movie.providers_id];
  
        // Parcourir chaque pays dans providers_id
        const hasProvider = providers.some(providerObj => {
          return Object.entries(providerObj).some(([countryCode, country]) => {
            if (!country || !country.types) {
              console.log(`No types for country ${countryCode} in movie: ${movie.title}`);
              return false; // Si aucun type de diffusion n'est présent, passer au suivant
            }
  
            // Vérifier la présence du fournisseur et récupérer le logo dans les types de diffusion
            const foundInFlatrate = country.types.flatrate?.some(providerArray => {
              if (providerArray[0] === selectedProvider) {
                selectedProviderLogo = providerArray[1]; // Récupère le chemin du logo
                return true;
              }
              return false;
            });
  
            const foundInRent = country.types.rent?.some(providerArray => {
              if (providerArray[0] === selectedProvider) {
                selectedProviderLogo = providerArray[1]; // Récupère le chemin du logo
                return true;
              }
              return false;
            });
  
            const foundInBuy = country.types.buy?.some(providerArray => {
              if (providerArray[0] === selectedProvider) {
                selectedProviderLogo = providerArray[1]; // Récupère le chemin du logo
                return true;
              }
              return false;
            });
  
            return foundInFlatrate || foundInRent || foundInBuy;
          });
        });
  
        return hasProvider;
      });
    }
  
    console.log("Selected Provider Logo:", selectedProviderLogo); // Log pour vérifier le logo sélectionné
    console.log("Filtered Movies:", filtered); // Log pour débogage
    setFilteredMovies(filtered);
  
    // Si tu veux afficher le logo directement après la sélection
    if (selectedProviderLogo) {
      setSelectedProviderLogo(selectedProviderLogo); // Stocke le logo dans un état
    }
  }, [selectedGenre, selectedProvider, movies]);
  

  
  
  const handleAddMovie = (movie) => {
    setSelectedMovie(movie);
    setShowModal(true);
  };

  const handleSelectList = async (listId) => {
    setIsLoading(true);
    try {
      await axios.post(
        `https://www.watchly.ovh/api/lists/${listId}/movies`,
        { movie: selectedMovie },
        { withCredentials: true }
      );

      setAddedMovies({ ...addedMovies, [selectedMovie.tmdb_id]: true });
      closeModal();
    } catch (error) {
      console.error("Erreur lors de l'ajout du film à la liste :", error);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedMovie(null);
  };

  const showMoreMovies = () => {
    setVisibleMoviesCount((prevCount) => prevCount + 30);
  };

  const handleSelectProvider = (event) => {
    setSelectedProvider(event.target.value);
  };

  return (
    <div>
      <Navbar />
      <div className="filter">
  <div className="select-container">
    <div className="genre-column">
      <select
        value={selectedGenre}
        onChange={(e) => setSelectedGenre(e.target.value)}
        className="genre-dropdown"
      >
        <option value="All">Tous les genres</option>
        {uniqueGenres.map((genre, index) => (
          <option key={index} value={genre.id_genres}>
            {genre.name}
          </option>
        ))}
      </select>
    </div>

    <div className="provider-column">
      <select
        value={selectedProvider}
        onChange={handleSelectProvider}
        className="provider-dropdown"
      >
        <option value="">Toutes les plateformes</option>
        {providers.length > 0 ? (
          providers.map((provider, index) => (
            <option key={index} value={provider}>
              {provider}
            </option>
          ))
        ) : (
          <option value="">Aucun fournisseur disponible</option>
        )}
      </select>
    </div>
  </div>
</div>


<div className="moviePage-listContainer">
  {filteredMovies.length === 0 ? (
    <h2>Aucun film disponible</h2>
  ) : (
    filteredMovies.slice(0, visibleMoviesCount).map((movie, index) => (
      <div key={index} className="moviePage-item">
        <button
          className={`moviePage-addButton ${addedMovies[movie.tmdb_id] ? "added" : ""}`}
          onClick={() => handleAddMovie(movie)}
        >
          <img
            src={addedMovies[movie.tmdb_id] ? checkIcon : addIcon}
            alt={addedMovies[movie.tmdb_id] ? "Added" : "Add"}
          />
        </button>

        <Link to={`/movie/${movie.tmdb_id}`}>
          <div className="moviePage-imageContainer">
            <img
              src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
              alt={movie.title}
            />
            <div className="moviePage-ratingImdb">
              <img src={imdbLogo} alt="IMDB Logo" />
              <span>{movie.vote_average ? movie.vote_average.toFixed(1) : "N/A"}</span> / 10
            </div>
            <div className="moviePage-ratingWatchly">
              <img src={watchlyLogo} alt="Watchly Logo" />
              <span>
                <AverageRating movieId={movie.tmdb_id} refreshTrigger={0} />
              </span>
            </div>
          </div>
          <div className="moviePage-titleContainer">
            <p>{truncateText(movie.title, 12)}</p>
            <p>({new Date(movie.release_date).getFullYear()})</p>
          </div>
        </Link>
      </div>
    ))
  )}

      </div>
      {visibleMoviesCount < filteredMovies.length && (
        <button onClick={showMoreMovies} className="moviePage-showMoreButton">
          <img
            src={rightArrowIcon}
            alt="Arrow Icon"
            className="moviePage-showMoreIcon"
          />
          Afficher Plus
        </button>
      )}
      {showModal && (
        <div className="userlist-create-list-modal-container">
          <div className="userlist-create-list-modal">
            <h2>Choisir une liste pour ajouter le film</h2>
            {isLoading ? (
              <div className="userlist-loading-spinner">
                <div className="userlist-spinner"></div>
              </div>
            ) : (
              <>
                {userLists.length === 0 ? (
                  <div>
                    <p>Aucune liste créée.</p>
                    <Link to="/userlist" onClick={() => window.scrollTo(0, 0)}>
                      <button className="userlist-list-button">Créer une liste</button>
                    </Link>
                  </div>
                ) : (
                  <ul>
                    {userLists.map((list) => (
                      <li key={list._id}>
                        <button className="userlist-list-button" onClick={() => handleSelectList(list._id)}>
                          {list.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
                <button className="userlist-close-button" onClick={closeModal}>Fermer</button>
              </>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default MoviePage;