import React from 'react';
import "../assets/styles/Services.css"; 
import Navbar from '../components/navbar'; 
import Footer from '../components/footer'; 
const ServicesPage = () => {
  return (
    <div>
        <Navbar />
    <div className="services-container">
      <section className="intro-section">
        <h1>Nos Services</h1>
        <p>
          Chez <span className="highlight">Watchly</span>, nous proposons une gamme de services pour améliorer votre expérience de recherche de films ou séries et vous offrir les meilleurs contenus au moment qui vous convient le mieux.
        </p>
      </section>

      <section className="service-list-section">
        <h2>Découvrez nos services</h2>

        <div className="service">
          <h3>1. Application Réactive</h3>
          <p>
            Profitez d'une <strong>application réactive</strong> qui s'adapte instantanément à vos actions. Que vous naviguiez sur notre catalogue, ajoutiez des films à votre liste ou changiez de périphérique, Watchly assure une fluidité d'utilisation grâce à des technologies modernes de <em>single-page application</em> (SPA). Notre interface est conçue pour une performance optimale sur tous vos appareils.
          </p>
        </div>

        <div className="service">
          <h3>2. Catalogue Mis à Jour</h3>
          <p>
            Accédez à un vaste catalogue de films, séries et documentaires, mis à jour régulièrement avec les dernières nouveautés du monde du divertissement.
          </p>
        </div>

        <div className="service">
        <h3>2. Système de Collaboration</h3>
          <p>
            Sur Watchly, nous croyons en la force de la communauté. Notre plateforme permet aux utilisateurs de :
          </p>
          <ul>
            <li>Noter les films et séries qu'ils regardent.</li>
            <li>Ajouter des commentaires pour partager leurs opinions, recommandations ou critiques.</li>
            <li>Interagir avec les autres membres de la communauté en aimant ou répondant à leurs commentaires.</li>
          </ul>
          <p>
            Ce système de collaboration vous permet non seulement de partager votre avis, mais aussi de découvrir de nouveaux contenus grâce aux retours et recommandations des autres utilisateurs.
          </p>
        </div>

      </section>

      

      <section className="cta-section">
        <h2>Découvrez l’expérience Watchly dès aujourd'hui !</h2>
        <p>
          Inscrivez-vous pour profiter de tous nos services et découvrir une nouvelle façon de consommer du contenu audiovisuel.
        </p>
      </section>
    </div>
    <Footer />
    </div>
  );
};

export default ServicesPage;
