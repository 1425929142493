import React, { useState, useEffect } from "react";
import axios from "axios";
let websiteUrl;
if (process.env.REACT_APP_BACKEND_ENVIRONMENT === "PROD") {
  websiteUrl = process.env.REACT_APP_BACKEND_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_BACKEND_ENVIRONMENT === "DEV") {
  websiteUrl = process.env.REACT_APP_BACKEND_WEBSITE_URL_DEV;
} else {
  websiteUrl = process.env.REACT_APP_BACKEND_WEBSITE_URL_PROD;
}
const fetchGenres = async () => {
    try {
      const response = await axios.get(`https://www.watchly.ovh/api/genres`);
      return response.data; // Supposons que tu reçoives un tableau d'objets genre
    } catch (error) {
      console.error("Erreur lors de la récupération des genres :", error);
      return [];
    }
  };
const GenreSelector = () => {
    const [uniqueGenres, setUniqueGenres] = useState([]);
    const [selectedGenre, setSelectedGenre] = useState("All");
  
    useEffect(() => {
      const fetchUniqueGenres = async () => {
        const genres = await fetchGenres();
        // Créer une liste unique de noms de genres
        const genreNames = genres.map((genre) => genre.name);
        setUniqueGenres([...new Set(genreNames)]);
      };
  
      fetchUniqueGenres();
    }, []);
  
    return (
      <select
        value={selectedGenre}
        onChange={(e) => setSelectedGenre(e.target.value)}
        className="genre-dropdown"
      >
        <option value="All">Tous les genres</option>
        {uniqueGenres.map((genre, index) => (
          <option key={index} value={genre}>
            {genre}
          </option>
        ))}
      </select>
    );
  };
export default GenreSelector;
