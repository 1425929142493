import React, { useState } from "react";
import axios from "axios";
import "../assets/styles/authModal.css";
import GoogleIcon from "../assets/img/google-icon.png";
import { Link, useNavigate } from "react-router-dom";
let websiteUrl;
if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_DEV;
} else {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
}
const AuthModal = ({ showModal, toggleModal }) => {
  const [activeTab, setActiveTab] = useState("login");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("https://www.watchly.ovh/api/auth/register", {
        name,
        email,
        password,
          confirmPassword,
        });

      if (res.data.success) {
        setSuccess(res.data.message); 
        setError("");
      } else {
        setError(res.data.message);
        setSuccess("");
      }
    } catch (err) {
      console.error("Erreur lors de l'inscription:", err);
      setError("Erreur du serveur. Veuillez réessayer plus tard.");
      setSuccess("");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("https://www.watchly.ovh/api/auth/login", {
        email,
        password,
      });

      if (res.data.success) {
        setSuccess(res.data.message);
        setError("");
        toggleModal();
        navigate("/");
      } else {
        setError(res.data.message || "Email ou mot de passe incorrect.");
        setSuccess("");
      }
    } catch (err) {
      console.error("Erreur lors de la connexion:", err);
      setError("Erreur du serveur. Veuillez réessayer plus tard.");
      setSuccess("");
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = "http://www.watchly.ovh:4000/auth/google";
  };

  return (
    <>
      {showModal && (
        <div className="modal">
          <div className="modal-content"> 
            <span className="close-button" onClick={toggleModal}>
              &times;
            </span>
            {activeTab === "login" && (
              <>
                <h2>Se connecter.</h2>
                <div className="social-login">
                  <button
                    className="customGoogleButton"
                    onClick={handleGoogleLogin}
                  >
                    <img src={GoogleIcon} alt="Google" />
                    Se connecter avec Google
                  </button>
                </div>
                <div className="tabs">
                  <button
                    className={`tab ${activeTab === "login" ? "active" : ""}`}
                    onClick={() => setActiveTab("login")}
                  >
                    Se connecter
                  </button>
                  <button
                    className={`tab ${activeTab === "signup" ? "active" : ""}`}
                    onClick={() => setActiveTab("signup")}
                  >
                    S'inscrire
                  </button>
                </div>
                {error && <div className="alert alert-warning">{error}</div>}
                <form id="authForm" onSubmit={handleLogin}>
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <input
                    type="password"
                    placeholder="Mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <button className="test" type="submit">Se connecter</button>
                  <Link to="/forgot" className="password-forgot">
                    Mot de passe oublié ?
                  </Link>
                </form>
              </>
            )}
            {activeTab === "signup" && (
              <>
                <h2>Créez votre compte.</h2>
                <div className="social-login">
                  <button
                    className="customGoogleButton"
                    onClick={handleGoogleLogin}
                  >
                    <img src={GoogleIcon} alt="Google" />
                    S'inscrire avec Google
                  </button>
                </div>
                <div className="tabs">
                  <button
                    className={`tab ${activeTab === "login" ? "active" : ""}`}
                    onClick={() => setActiveTab("login")}
                  >
                    Se connecter
                  </button>
                  <button
                    className={`tab ${activeTab === "signup" ? "active" : ""}`}
                    onClick={() => setActiveTab("signup")}
                  >
                    S'inscrire
                  </button>
                </div>
                {error && <div className="alert alert-warning">{error}</div>}
                {success && (
                  <div className="alert alert-success">{success}</div>
                )}
                <form id="authForm" onSubmit={handleSignupSubmit}>
                  <input
                    type="text"
                    placeholder="Nom"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <input
                    type="password"
                    placeholder="Mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <input
                    type="password"
                    placeholder="Confirmer le mot de passe"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <button type="submit">S'inscrire</button>
                </form>
                <div className="disclaimer">
                  En créant un compte, vous consentez à ce que vos données
                  soient collectées pour générer des recommandations, des études
                  de marché et améliorer le service.
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AuthModal;
