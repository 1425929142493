import React from "react";
import "../assets/styles/banner.css";
import bannerImage from "../assets/img/bannerImage.png";

const Banner = () => {
  return (
    <div className="bannerContainer">
      <div className="bannerImageContainer">
        <img src={bannerImage} alt="Banner" className="bannerImage" />
      </div>
      <div className="bannerContent">
        <h1>Reprenez là où vous vous êtes arrêté. </h1>
        <p>
          ...à partir de plus de 50 services, dont Netflix, Hulu, Apple TV, Disney+,
          Prime Video, des services gratuits, et bien plus encore !
        </p>
      </div>
    </div>
  );
};

export default Banner;
