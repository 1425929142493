import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import imdbLogo from "../assets/img/imdb-logo.svg";
import watchlyLogo from "../assets/img/watchly-logo.png";
import addIcon from "../assets/img/add-button.png";
import checkIcon from "../assets/img/check-icon.png";
import AverageRating from "../components/averageRating";
import "../assets/styles/moviePage.css";
import "../assets/styles/listButton.css";
import Footer from "../components/footer";
import rightArrowIcon from "../assets/img/arrowRight.png";

let websiteUrl;

if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_DEV;
} else {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
}

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};

const SeriePage = () => {
  const [series, setSeries] = useState([]);
  const [filteredSeries, setFilteredSeries] = useState([]); // Pour stocker les séries filtrées
  const [addedSeries, setAddedSeries] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedSerie, setSelectedSerie] = useState(null);
  const [userLists, setUserLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleSeriesCount, setVisibleSeriesCount] = useState(30);

  const [uniqueGenres, setUniqueGenres] = useState([]); // État pour les genres
  const [selectedGenre, setSelectedGenre] = useState("All");
  const [selectedProvider, setSelectedProvider] = useState('');
  const [providers, setProviders] = useState([]);
  const [selectedProviderLogo, setSelectedProviderLogo] = useState('');

  // Récupérer les séries et genres
  useEffect(() => {
    const fetchSeriesAndGenres = async () => {
      try {
        const response = await axios.get(`https://www.watchly.ovh/api/series`);
        const sortedSeries = response.data.sort((a, b) => b.popularity - a.popularity);
        setSeries(sortedSeries);
        setFilteredSeries(sortedSeries); // Initialiser les séries filtrées avec toutes les séries

        // Extraire les providers uniques
        const allProviders = new Set();

        sortedSeries.forEach(serie => {
          if (Array.isArray(serie.providers_id)) {
            serie.providers_id.forEach(country => {
              Object.entries(country).forEach(([countryCode, countryData]) => {
                if (countryData && countryData.types) {
                  ['flatrate', 'rent', 'buy'].forEach(type => {
                    if (Array.isArray(countryData.types[type])) {
                      countryData.types[type].forEach(providerArray => {
                        if (Array.isArray(providerArray) && providerArray.length > 0) {
                          allProviders.add(providerArray[0]); // Ajoute le nom de la plateforme
                        }
                      });
                    }
                  });
                }
              });
            });
          }
        });

        const providersArray = Array.from(allProviders);
        setProviders(providersArray); // Met à jour l'état des fournisseurs

        // Récupérer les genres
        const genresResponse = await axios.get(`https://www.watchly.ovh/api/genres`);
        setUniqueGenres(genresResponse.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des séries ou des genres :", error);
      }
    };

    fetchSeriesAndGenres();
  }, []);

  useEffect(() => {
    const fetchUserLists = async () => {
      try {
        const response = await axios.get(`https://www.watchly.ovh/api/lists`, { withCredentials: true });
        setUserLists(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des listes de l'utilisateur :", error);
      }
    };

    fetchUserLists();
  }, []);

  useEffect(() => {
    const addedSeriesMap = {};

    userLists.forEach((list) => {
      if (list.series) {
        list.series.forEach((serie) => {
          addedSeriesMap[serie.tmdb_id] = true;
        });
      }
    });

    setAddedSeries(addedSeriesMap);
  }, [userLists]);

  useEffect(() => {
    let filtered = series;

    // Filtrer par genre sélectionné
    if (selectedGenre !== "All") {
      filtered = filtered.filter(serie => serie.genre && serie.genre.includes(Number(selectedGenre)));
    }

    // Variable pour stocker le logo du fournisseur sélectionné
    let selectedProviderLogo = '';

    // Filtrer par fournisseur sélectionné
    if (selectedProvider) {
      filtered = filtered.filter(serie => {
        if (!serie.providers_id) {
          return false;
        }

        const providers = Array.isArray(serie.providers_id) ? serie.providers_id : [serie.providers_id];

        const hasProvider = providers.some(providerObj => {
          return Object.entries(providerObj).some(([countryCode, country]) => {
            if (!country || !country.types) {
              return false;
            }

            const foundInFlatrate = country.types.flatrate?.some(providerArray => {
              if (providerArray[0] === selectedProvider) {
                selectedProviderLogo = providerArray[1];
                return true;
              }
              return false;
            });

            const foundInRent = country.types.rent?.some(providerArray => {
              if (providerArray[0] === selectedProvider) {
                selectedProviderLogo = providerArray[1];
                return true;
              }
              return false;
            });

            const foundInBuy = country.types.buy?.some(providerArray => {
              if (providerArray[0] === selectedProvider) {
                selectedProviderLogo = providerArray[1];
                return true;
              }
              return false;
            });

            return foundInFlatrate || foundInRent || foundInBuy;
          });
        });

        return hasProvider;
      });
    }

    setFilteredSeries(filtered);
    if (selectedProviderLogo) {
      setSelectedProviderLogo(selectedProviderLogo);
    }
  }, [selectedGenre, selectedProvider, series]);

  const handleAddSerie = (serie) => {
    setSelectedSerie(serie);
    setShowModal(true);
  };

  const handleSelectList = async (listId) => {
    setIsLoading(true);
    try {
      await axios.post(
        `https://www.watchly.ovh/api/lists/${listId}/series`,
        { serie: selectedSerie },
        { withCredentials: true }
      );

      setAddedSeries({ ...addedSeries, [selectedSerie.tmdb_id]: true });
      closeModal();
    } catch (error) {
      console.error("Erreur lors de l'ajout de la série à la liste :", error);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedSerie(null);
  };

  // Fonction pour afficher plus de séries
  const showMoreSeries = () => {
    setVisibleSeriesCount((prevCount) => prevCount + 40);
  };

  return (
    <div>
      <Navbar />
      <div className="filter">
  <div className="select-container">
    <div className="genre-column">
      <select
        value={selectedGenre}
        onChange={(e) => setSelectedGenre(e.target.value)}
        className="genre-dropdown"
      >
        <option value="All">Tous les genres</option>
        {uniqueGenres.map((genre, index) => (
          <option key={index} value={genre.id_genres}>
            {genre.name}
          </option>
        ))}
      </select>
    </div>

    <div className="provider-column">
    <select
  value={selectedProvider}
  onChange={(e) => setSelectedProvider(e.target.value)} 
  className="provider-dropdown"
>
  <option value="">Toutes les plateformes</option>
  {providers.length > 0 ? (
    providers.map((provider, index) => (
      <option key={index} value={provider}>
        {provider}
      </option>
    ))
  ) : (
    <option value="">Aucun fournisseur disponible</option>
  )}
</select>

    </div>
  </div>
</div>

      <div className="moviePage-listContainer">
        {filteredSeries.length === 0 ? (
          <h2>Aucune série disponible</h2>
        ) : (
          filteredSeries.slice(0, visibleSeriesCount).map((serie, index) => (
            <div key={index} className="moviePage-item">
              <button
                className={`moviePage-addButton ${addedSeries[serie.tmdb_id] ? "added" : ""}`}
                onClick={() => handleAddSerie(serie)}
              >
                <img
                  src={addedSeries[serie.tmdb_id] ? checkIcon : addIcon}
                  alt={addedSeries[serie.tmdb_id] ? "Added" : "Add"}
                />
              </button>

              <Link to={`/serie/${serie.tmdb_id}`}>
                <div className="moviePage-imageContainer">
                  <img
                    src={`https://image.tmdb.org/t/p/w500${serie.poster_path}`}
                    alt={serie.title}
                  />
                  <div className="moviePage-ratingImdb">
                    <img src={imdbLogo} alt="IMDB Logo" />
                    <span>{serie.vote_average ? serie.vote_average.toFixed(1) : "N/A"}</span> / 10
                  </div>
                  <div className="moviePage-ratingWatchly">
                    <img src={watchlyLogo} alt="Watchly Logo" />
                    <span>
                      <AverageRating movieId={serie.tmdb_id} refreshTrigger={0} />
                    </span>
                  </div>
                </div>
                <div className="moviePage-titleContainer">
                  <p>{truncateText(serie.title, 12)}</p>
                      <p>({new Date(serie.release_date).getFullYear()})</p>
                </div>
              </Link>
            </div>
          ))
        )}
      </div>

      {/* Bouton pour afficher plus de séries */}
      {visibleSeriesCount < filteredSeries.length && (
        <button onClick={showMoreSeries} className="moviePage-showMoreButton">
          <img src={rightArrowIcon} alt="Arrow Icon" className="moviePage-showMoreIcon" />
          Afficher Plus
        </button>
      )}

      {showModal && (
        <div className="userlist-create-list-modal-container">
          <div className="userlist-create-list-modal">
            <h2>Choisir une liste pour ajouter la Série</h2>
            {isLoading ? (
              <div className="userlist-loading-spinner">
                <div className="userlist-spinner"></div>
              </div>
            ) : (
              <>
                {userLists.length === 0 ? (
                  <div>
                    <p>Aucune liste créée.</p>
                    <Link to="/userlist" onClick={() => window.scrollTo(0, 0)}>
                      <button className="userlist-list-button">Créer une liste</button>
                    </Link>
                  </div>
                ) : (
                  <ul>
                    {userLists.map((list) => (
                      <li key={list._id}>
                        <button className="userlist-list-button" onClick={() => handleSelectList(list._id)}>
                          {list.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
                <button className="userlist-close-button" onClick={closeModal}>Fermer</button>
              </>
            )}
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default SeriePage;
