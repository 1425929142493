import React from 'react';
import "../assets/styles/PrivacyPolicy.css";
import Navbar from '../components/navbar';
import Footer from '../components/footer';

const PrivacyPolicyPage = () => {
  return (
    <div>
        <Navbar />
    <div className="privacy-container">
      <section className="intro-section">
        <h1>Politique de Confidentialité</h1>
        <p>
          Chez <span className="highlight">Watchly</span>, nous respectons et protégeons votre vie privée. Cette politique de confidentialité explique comment nous recueillons, utilisons, partageons et protégeons vos informations personnelles lorsque vous utilisez notre plateforme.
        </p>
      </section>

      <section className="section">
        <h2>1. Collecte des Informations</h2>
        <p>
          Nous recueillons différents types d'informations lorsque vous utilisez Watchly :
        </p>
        <ul>
          <li><strong>Informations fournies directement</strong> : Lorsque vous créez un compte, vous nous fournissez des informations telles que votre nom, votre adresse e-mail et vos préférences de visionnage.</li>
          <li><strong>Informations recueillies automatiquement</strong> : Nous collectons également des informations sur votre utilisation de notre plateforme, comme l'historique de navigation, les films ou séries visionnés, et l'adresse IP.</li>
        </ul>
      </section>


      <section className="section">
        <h2>2. Partage des Données</h2>
        <p>
          Nous ne partageons vos informations personnelles avec des tiers que dans les cas suivants :
        </p>
        <ul>
          <li><strong>Avec votre consentement</strong> : Nous partageons vos données uniquement lorsque vous nous donnez explicitement votre accord.</li>
          <li><strong>Conformité légale</strong> : Si la loi l'exige, nous pouvons être amenés à partager vos informations pour répondre à une procédure judiciaire ou une enquête gouvernementale.</li>
        </ul>
      </section>

      <section className="section">
        <h2>3. Sécurité des Informations</h2>
        <p>
          La sécurité de vos informations personnelles est essentielle pour nous. Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles pour protéger vos données contre tout accès non autorisé, altération ou destruction. Cependant, aucun système de sécurité n'est infaillible, et nous ne pouvons garantir une protection absolue.
        </p>
      </section>


      <section className="section">
        <h2>4. Modifications de cette Politique</h2>
        <p>
          Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Si des changements sont apportés, nous publierons la nouvelle version sur cette page et vous en informerons par e-mail ou via une notification sur notre plateforme.
        </p>
      </section>

      <section className="section">
        <h2>5. Contact</h2>
        <p>
          Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, ou si vous souhaitez exercer l'un de vos droits, veuillez nous contacter à l'adresse suivante :
        </p>
        <p>Email : <a href="mailto:support@watchly.com">support@watchly.com</a></p>
      </section>
    </div>
    <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
