import React from "react";
import Navbar from "../components/navbar";
import ResetPasswordPage from "../components/resetPassword";
import Footer from "../components/footer";

const resetPasswordPage = () => {
  return (
    <div>
      <Navbar />
      <ResetPasswordPage />
      <Footer />
    </div>
  );
};

export default resetPasswordPage;
