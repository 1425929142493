import React, { useState } from "react";
import { useParams } from "react-router-dom"; // Importer useParams depuis react-router-dom
import axios from "axios";
import "../assets/styles/resetPassword.css";
let websiteUrl;
if (process.env.ENVIRONMENT === "PROD") {
    websiteUrl = process.env.WEBSITE_URL_PROD;
} else if (process.env.ENVIRONMENT === "DEV") {
    websiteUrl = process.env.WEBSITE_URL_DEV;
  } else {
    websiteUrl = process.env.WEBSITE_URL_DEFAULT;
}
function ResetPassword() {
  const { token } = useParams(); // Utiliser useParams pour récupérer les paramètres d'URL
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      setSuccess("");
      return;
    }

    if (password.length < 8) {
      setError("Le mot de passe doit comporter au moins 8 caractères.");
      setSuccess("");
      return;
    }

    try {
      const res = await axios.post(
        `https://www.watchly.ovh/api/auth/reset/${token}`,
        {
          password,
          password2: confirmPassword,
        }
      );

      if (res.data.success) {
        setSuccess(res.data.message);
        setError("");
      } else {
        setError(
          res.data.message ||
            "Erreur lors de la réinitialisation du mot de passe."
        );
        setSuccess("");
      }
    } catch (err) {
      console.error(err);
      setError("Erreur du serveur. Veuillez réessayer plus tard.");
      setSuccess("");
    }
  };

  return (
    <div className="reset-modal">
      <div className="reset-modal-content">
        <h2>Réinitialiser votre mot de passe</h2>
        <form onSubmit={handleSubmit} id="resetPasswordForm">
          <input
            type="password"
            className="new-password-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Nouveau mot de passe"
            required
          />
          <input
            type="password"
            className="new-password-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirmer le nouveau mot de passe"
            required
          />
          <button type="submit" className="new-reset-button">
            Réinitialiser
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
      </div>
    </div>
  );
}

export default ResetPassword;
