import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import "../assets/styles/movieList.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imdbLogo from "../assets/img/imdb-logo.svg";
import watchlyLogo from "../assets/img/watchly-logo.png";
import addIcon from "../assets/img/add-button.png";
import checkIcon from "../assets/img/check-icon.png";
import leftArrowIcon from "../assets/img/arrowLeft.png";
import rightArrowIcon from "../assets/img/arrowRight.png";
import AverageRating from "./averageRating";
import "../assets/styles/listButton.css";

let websiteUrl;
if (process.env.REACT_APP_BACKEND_ENVIRONMENT === "PROD") {
  websiteUrl = process.env.REACT_APP_BACKEND_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_BACKEND_ENVIRONMENT === "DEV") {
  websiteUrl = process.env.REACT_APP_BACKEND_WEBSITE_URL_DEV;
} else {
  websiteUrl = process.env.REACT_APP_BACKEND_WEBSITE_URL_PROD;
}
const MovieList = ({ userLists }) => {
  const [movies, setMovies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [addedMovies, setAddedMovies] = useState({});
  const [isLoading, setIsLoading] = useState(false); 
  const [visibleMoviesCount, setVisibleMoviesCount] = useState(40); 

useEffect(() => {
  const fetchMovies = async () => {
    try {
      const response = await axios.get(`https://www.watchly.ovh/api/movies`);
      const sortedMovies = response.data.sort((a, b) => b.popularity - a.popularity);
      setMovies(sortedMovies.slice(0, visibleMoviesCount)); 
    } catch (error) {
      console.error("Erreur lors de la récupération des films :", error);
    }
  };

  fetchMovies();
}, [visibleMoviesCount]);

  useEffect(() => {
    const addedMoviesMap = {};
    userLists.forEach((list) => {
      list.movies.forEach((movie) => {
        addedMoviesMap[movie.tmdb_id] = true;
      });
    });
    setAddedMovies(addedMoviesMap);
  }, [userLists]);

  const PrevArrow = ({ className, onClick }) => (
    <img 
      src={leftArrowIcon} 
      alt="Previous" 
      className={`slick-prev ${className}`} 
      onClick={onClick} 
    />
  );

  const NextArrow = ({ className, onClick }) => (
    <img 
      src={rightArrowIcon} 
      alt="Next" 
      className={`slick-next ${className}`} 
      onClick={onClick} 
    />
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleAddMovie = (movie) => {
    setSelectedMovie(movie);
    setShowModal(true);
  };

  const handleSelectList = async (listId) => {
    try {
      setIsLoading(true); // Déclenche le chargement
      await axios.post(
        `https://www.watchly.ovh/api/lists/${listId}/movies`,
        { movie: selectedMovie }, // Envoi de l'objet movie avec tmdb_id
        { withCredentials: true }
      );

      setAddedMovies({ ...addedMovies, [selectedMovie.tmdb_id]: true });
      closeModal();
    } catch (error) {
      console.error("Erreur lors de l'ajout du film à la liste :", error);
    } finally {
      setIsLoading(false); // Termine le chargement
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedMovie(null);
  };

  return (
    <div className="movieListContainer">
      <button className="titleButton">
        Films Populaires
        <img src={rightArrowIcon} alt="Arrow" className="arrowIcon" />
      </button>
      <Slider {...settings}>
        {movies.map((movie, index) => (
          <div key={index} className="movieItem">
            <div className="movieImageContainer">
              <button
                className={`addButton ${addedMovies[movie.tmdb_id] ? "added" : ""}`}
                onClick={() => handleAddMovie(movie)}
              >
                <img
                  src={addedMovies[movie.tmdb_id] ? checkIcon : addIcon}
                  alt={addedMovies[movie.tmdb_id] ? "Added" : "Add"}
                  className="addIcon"
                />
              </button>
              <Link to={`/movie/${movie.tmdb_id}`}>
                <img
                  src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                  alt={`Poster of ${movie.title}`}
                />
              </Link>
              <div className="movieRating">
                <img src={imdbLogo} alt="IMDB Logo" className="imdbLogo" />
                <span className="ratingValue">{movie.vote_average.toFixed(1)}</span> / 10
              </div>
              <div className="watchlyRating">
                <img src={watchlyLogo} alt="Watchly Logo" className="watchlyLogo" />
                <span className="ratingValue">
                  <AverageRating movieId={movie.tmdb_id} refreshTrigger={0} />
                </span>
              </div>
            </div>
            <div className="movieTitleContainer">
              <Link to={`/movie/${movie.tmdb_id}`}>
                <h3>{movie.title}</h3>
              </Link>
              <p>({new Date(movie.release_date).getFullYear()})</p>
            </div>
          </div>
        ))}
      </Slider>

      {showModal && (
        <div className="userlist-create-list-modal-container">
          <div className="userlist-create-list-modal">
            <div className="modal-content">
              <h2>Choisir une liste pour ajouter le Film</h2>

              {isLoading ? (
                <div className="userlist-loading-spinner">
                  <div className="userlist-spinner"></div> {/* Spinner de chargement */}
                </div>
              ) : userLists.length === 0 ? (
                <div>
                  <p className="userlist-error-message">Aucune liste créée.</p>
                  <Link to="/userlist" onClick={() => window.scrollTo(0, 0)}>
                    <button className="userlist-list-button">
                      Créer une liste
                    </button>
                  </Link>
                </div>
              ) : (
                <ul className="userlist-ul">
                  {userLists.map((list) => (
                    <li key={list._id}>
                      <button
                        className="userlist-list-button"
                        onClick={() => handleSelectList(list._id)}
                      >
                        {list.name}
                      </button>
                    </li>
                  ))}
                </ul>
              )}

              <button className="userlist-close-button" onClick={closeModal}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MovieList;
