import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import "../assets/styles/movieList.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imdbLogo from "../assets/img/imdb-logo.svg";
import watchlyLogo from "../assets/img/watchly-logo.png";
import addIcon from "../assets/img/add-button.png";
import checkIcon from "../assets/img/check-icon.png";
import leftArrowIcon from "../assets/img/arrowLeft.png";
import rightArrowIcon from "../assets/img/arrowRight.png";
import AverageRating from "../components/averageRating";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
let websiteUrl;
if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
} else if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_DEV;
} else {
  websiteUrl = process.env.REACT_APP_WEBSITE_URL_PROD;
}
const NewReleasePage = () => {
  const [movies, setMovies] = useState([]);
  const [series, setSeries] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addedItems, setAddedItems] = useState({});
  const [userLists, setUserLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false); 
  const [addedMovies, setAddedMovies] = useState({});
  const [selectedMovie, setSelectedMovie] = useState(null);
  
  const [visibleMoviesCount, setVisibleMoviesCount] = useState(40);
  const [visibleSeriesCount, setVisibleSeriesCount] = useState(40); 

  useEffect(() => {
    const fetchMoviesAndSeries = async () => {
      try {
        // Récupérer les films
        const movieResponse = await axios.get(`https://www.watchly.ovh/api/movies`);
        const sortedMovies = movieResponse.data.sort(
          (a, b) => new Date(b.release_date) - new Date(a.release_date)
        );
        setMovies(sortedMovies.slice(0, visibleMoviesCount)); 
  
        // Récupérer les séries
        const seriesResponse = await axios.get(`https://www.watchly.ovh/api/series`);
        const sortedSeries = seriesResponse.data.sort(
          (a, b) => new Date(b.release_date) - new Date(a.release_date)
        );
        setSeries(sortedSeries.slice(0, visibleSeriesCount)); 
      } catch (error) {
        console.error("Erreur lors de la récupération des films et séries :", error);
      }
    };
  
    fetchMoviesAndSeries();
  }, [visibleMoviesCount, visibleSeriesCount]);
  

  useEffect(() => {
    const fetchUserLists = async () => {
      try {
        const response = await axios.get(`https://www.watchly.ovh/api/lists`, { withCredentials: true });
        setUserLists(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des listes de l'utilisateur :", error);
      }
    };

    fetchUserLists();
  }, []);

  useEffect(() => {
    const addedItemsMap = {};

    userLists.forEach((list) => {
      list.movies.forEach((item) => {
        addedItemsMap[item.tmdb_id] = true;
      });
    });

    setAddedItems(addedItemsMap);
  }, [userLists]);

  

  const PrevArrow = ({ className, onClick }) => (
    <img
      src={leftArrowIcon}
      alt="Previous"
      className={`slick-prev ${className}`}
      onClick={onClick}
    />
  );

  const NextArrow = ({ className, onClick }) => (
    <img
      src={rightArrowIcon}
      alt=""
      className={`slick-next ${className}`}
      onClick={onClick}
    />
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleAddItem = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };
  const handleSelectList = async (listId) => {
    setIsLoading(true);
    try {
      await axios.post(
        `https://www.watchly.ovh/api/lists/${listId}/movies`,
        { movie: selectedItem },
        { withCredentials: true }
      );
  
    setAddedItems((prevItems) => ({
      ...prevItems,
      [selectedItem.tmdb_id]: true,
    }));
      closeModal();
    } catch (error) {
      console.error("Erreur lors de l'ajout du film à la liste :", error);
    } finally {
      setIsLoading(false); 
    }
  };
  

  const closeModal = () => {
    setShowModal(false);
    setSelectedMovie(null);
  };

  return (
    <div>
      <Navbar />

      <div className="movieListContainer">
        <button className="titleButton">
          Nouveaux Films
          <img src={rightArrowIcon} alt="Arrow" className="arrowIcon" />
        </button>
        <Slider {...settings}>
          {movies.map((movie, index) => (
            <div key={index} className="movieItem">
              <div className="movieImageContainer">
                <button
                  className={`addButton ${addedItems[movie.tmdb_id] ? "added" : ""}`}
                  onClick={() => handleAddItem(movie)}
                >
                  <img
                    src={addedItems[movie.tmdb_id] ? checkIcon : addIcon}
                    alt={addedItems[movie.tmdb_id] ? "Added" : "Add"}
                    className="addIcon"
                  />
                </button>
                <Link to={`/movie/${movie.tmdb_id}`}>
                  <img
                    src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                    alt={`Poster of ${movie.title}`}
                  />
<div className="movieRating">
  <img src={imdbLogo} alt="IMDB Logo" className="imdbLogo" />
  <span className="ratingValue">
    {movie.vote_average ? movie.vote_average.toFixed(1) : "0.0"}
  </span>{" "}
  / 10
</div>

                  <div className="watchlyRating">
                    <img src={watchlyLogo} alt="Watchly Logo" className="watchlyLogo" />
                    <span className="ratingValue">
                      <AverageRating movieId={movie.tmdb_id} refreshTrigger={0} />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="movieTitleContainer">
                <h3>{movie.title}</h3>
                <p>({new Date(movie.release_date).getFullYear()})</p>
              </div>
            </div>
          ))}
        </Slider>

        <button className="titleButton">
          Nouvelles Séries
          <img src={rightArrowIcon} alt="Arrow" className="arrowIcon" />
        </button>
        <Slider {...settings}>
          {series.map((serie, index) => (
            <div key={index} className="movieItem">
              <div className="movieImageContainer">
                <button
                  className={`addButton ${addedItems[serie.tmdb_id] ? "added" : ""}`}
                  onClick={() => handleAddItem(serie)}
                >
                  <img
                    src={addedItems[serie.tmdb_id] ? checkIcon : addIcon}
                    alt={addedItems[serie.tmdb_id] ? "Added" : "Add"}
                    className="addIcon"
                  />
                </button>
                <Link to={`/serie/${serie.tmdb_id}`}>
                  <img
                    src={`https://image.tmdb.org/t/p/w500${serie.poster_path}`}
                    alt={`Poster of ${serie.title}`}
                  />
<div className="movieRating">
  <img src={imdbLogo} alt="IMDB Logo" className="imdbLogo" />
  <span className="ratingValue">
    {serie.vote_average ? serie.vote_average.toFixed(1) : "0.0"}
  </span>{" "}
  / 10
</div>
                  <div className="watchlyRating">
                    <img src={watchlyLogo} alt="Watchly Logo" className="watchlyLogo" />
                    <span className="ratingValue">
                      <AverageRating movieId={serie.tmdb_id} refreshTrigger={0} />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="movieTitleContainer">
                <h3>{serie.title}</h3>
                <p>({new Date(serie.release_date).getFullYear()})</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {showModal && (
        <div className="userlist-create-list-modal-container"> 
          <div className="userlist-create-list-modal"> 
            <h2>Choisir une liste pour ajouter un élément</h2>
            {isLoading ? ( 
              <div className="userlist-loading-spinner">
                <div className="userlist-spinner"></div>
              </div>
            ) : (
              <>
                {userLists.length === 0 ? (
                  <div>
                    <p>Aucune liste créée.</p>
                    <Link to="/userlist" onClick={() => window.scrollTo(0, 0)}>
                      <button className="userlist-list-button">Créer une liste</button>
                    </Link>
                  </div>
                ) : (
                  <ul>
                    {userLists.map((list) => (
                      <li key={list._id}>
                        <button className="userlist-list-button" onClick={() => handleSelectList(list._id)}>
                          {list.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
                <button className="userlist-close-button" onClick={closeModal}>Fermer</button>
              </>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default NewReleasePage;