import React, { useState } from "react";
import "../assets/styles/listButton.css";
import addIcon from "../assets/img/add-button.png";
const CreateListButton = ({ onSave }) => {
  const [showModal, setShowModal] = useState(false);
  const [listName, setListName] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false); 


  const toggleModal = () => {
    setError(""); 
    setShowModal(!showModal);
  };


  const handleSave = async () => {
    if (listName.trim() === "") {
      setError("Le nom de la liste ne peut pas être vide."); 
      return;
    }

    setIsLoading(true); 
    await onSave(listName.trim()); 
    setListName(""); 
    setIsLoading(false); 
    toggleModal(); 
  };

  return (
<div className="userlist-list-button-container">
  <h1>Vos listes personnalisées</h1>
  <button className="userlist-list-button-details" onClick={toggleModal}>
    <img src={addIcon} alt="Ajouter" className="add-icon" /> 
    Créer une liste personnalisée
  </button>
      {showModal && (
        <div className="userlist-create-list-modal-container">
          <div className="userlist-create-list-modal">
            <div className="modal-content">
              <h2>Créer une nouvelle liste</h2>
              {error && <p className="userlist-error-message">{error}</p>} 
              <input
                type="text"
                value={listName}
                onChange={(e) => setListName(e.target.value)}
                placeholder="Nom de la liste"
                className="userlist-list-name-input"
              />
              <div className="userlist-modal-buttons">
                <button onClick={toggleModal}>Fermer</button>
                <button onClick={handleSave} disabled={isLoading}>
                  {isLoading ? "Création..." : "Sauvegarder"} 
                </button>
              </div>

              {isLoading && (
                <div className="userlist-loading-spinner">
                  <div className="userlist-spinner"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateListButton;
